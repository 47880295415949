function greet(){   
  console.log('greet here ');

  $(document).on('click', '.panel-item.can-collapse', function() {
    // console.log('index ', $('.panel-item.can-collapse').index(this));

    let rowIndex = $('.panel-item.can-collapse').index(this);
    
    // if ($(this).hasClass('can-collapse')) {
    //   var expand = $(this).next().height() + 45;
      
    //   $('.mat-mdc-table tr').eq(rowIndex+1).css("height", expand+"px");

    // }

    if(!$(this).next().hasClass('panel-item')) {
      
      var expand = $(this).next().height() + 45;
      
      $('.mat-mdc-table tr').eq(rowIndex+1).css("height", expand+"px");

    } else {
      
      $('.mat-mdc-table tr').eq(rowIndex+1).css("height", "45px");
    }+

    $('.timeline-item .default-content').each(function() {

      checkWord($(this), 'sign on');
      checkWord($(this), 'on board');
      checkWord($(this), 'training');
      checkWord($(this), 'sick');
      checkWord($(this), 'sign off');
      checkWord($(this), 'leave');
      checkWord($(this), 'traveling');
    
      function checkWord(element, param) {
        if (element.filter(function() {
          // console.log(element.text().toLowerCase().indexOf(param));

          return element.text().toLowerCase().indexOf(param) !== -1;
        }).length > 0) {

          if(param == 'sign on') {
            element.addClass('sign-on');
          } else if(param == 'sign off') {
            element.addClass('sign-off');
          } else if(param == 'on board') {
            element.addClass('on-board');
          } else if(param == 'training') {
            element.addClass('training');
          } else if(param == 'sick') {
            element.addClass('sick');
          } else if(param == 'leave') {
            element.addClass('leave');
          } else if(param == 'traveling') {
            element.addClass('traveling');
          }
          
          // console.log('The element contains the word '+param+' (case-insensitive)');
        }
      }
    });

  });
}